import axios from 'axios';
import debounce from 'debounce-promise';

const DEBOUNCE_TIMEOUT = 300;

const searchAddresses = debounce((searchString) => {
  const searchAddressUrl = `${process.env.VUE_APP_API_URL}/search`;

  const searchAddressParams = {
    s: searchString,
  };

  const searchResponse = axios.get(searchAddressUrl, {
    params: searchAddressParams,
  });

  return searchResponse;
}, DEBOUNCE_TIMEOUT);

const getAddressList = async (query) => {
  const isSearchStringNotValid = Boolean(!query || query?.length <= 2);
  if (isSearchStringNotValid) {
    return [];
  }

  const foundAddresses = await searchAddresses(query);

  const isAddressesNotFound = Boolean(!foundAddresses);
  if (isAddressesNotFound) {
    return [];
  }

  const addresses = foundAddresses.data.addresses.map((address) => address?.a);

  const addressOptions = [query, ...addresses];

  return addressOptions;
};

const searchRentalAddresses = debounce((searchString, availiableAddresses) => {
  const searchResponse = availiableAddresses.filter((item) => {
    if (item !== null || item !== '') {
      const filterResult = item.toLowerCase().includes(searchString.toLowerCase());
      return filterResult;
    }
    return false;
  });

  return searchResponse;
}, DEBOUNCE_TIMEOUT);

const getRentalAddressList = async (query, availiableAddresses) => {
  const foundAddresses = await searchRentalAddresses(query, availiableAddresses);

  const isAddressesNotFound = Boolean(!foundAddresses);
  if (isAddressesNotFound) {
    return [];
  }

  const addressOptions = [query, 'New property', ...foundAddresses];

  const filteredAddressOptions = addressOptions.filter((item) => item !== '');

  return filteredAddressOptions;
};

export {
  getAddressList,
  getRentalAddressList,
};
